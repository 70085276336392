<template>
  <div>
    <!--Breadcrumb-->
    <section>
      <Breadcrumb
        title="Blog"
        :array="[
          { islink: true, url: '/', text: 'Ana Sayfa' },
          { islink: false, url: '', text: 'Blog' },
        ]"
      />
    </section>
    <!--/Breadcrumb-->

    <!--Add listing-->
    <section class="sptb">
      <div class="container">
        <div class="row">
          <!--BLOG LIST-->
          <div class="col-xl-8 col-lg-8 col-md-12">
            <div class="row" v-if="blogLoad">
              <div
                v-for="(item, i) in 8"
                :key="'blogLoad-' + i"
                class="col-xl-6 col-lg-12 col-md-12"
              >
                <div class="card">
                  <div class="item7-card-img">
                    <div
                      style="height: 200px"
                      alt="img"
                      class="skeleton cover-image w-100"
                    ></div>
                  </div>
                  <div class="card-body">
                    <div class="item7-card-desc d-flex mb-2">
                      <div class="skeleton w-70 p-2"></div>
                      <div class="ms-auto skeleton w-20 p-2"></div>
                    </div>
                    <h4 class="skeleton w-100 font-weight-semibold p-2"></h4>
                    <div>
                      <div class="skeleton mt-2 w-70 p-2"></div>
                      <div class="skeleton mt-2 w-40 p-2"></div>
                      <div class="skeleton mt-2 w-10 p-2"></div>
                    </div>
                    <div class="skeleton mt-2 p-4 w-30"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="!blogLoad">
              <template v-if="blogList == null || blogList.length == 0">
                <div class="text-center"><h3>Sonuç Bulunamadı</h3></div>
              </template>
              <div
                v-for="(item, i) in blogList"
                :key="'blog-' + i"
                class="col-xl-6 col-lg-6 col-md-12"
                id="mobileEx"
              >
                <div class="card tItem">
                  <div class="item7-card-img">
                    <router-link :to="'/blog/' + item.short_name"></router-link>
                    <lazy-image
                      :src="
                        'https://storage.terapivitrini.com/' + item.thumbnail
                      "
                      style="width: 100%"
                      :alt="item.title"
                      class="cover-image"
                    />

                    <div class="item7-card-text">
                      <div
                        v-for="(s, i) in item.categories"
                        :key="'categories-' + i"
                      >
                        <router-link :to="'/blog/' + item.short_name">
                          <span class="badge badge-primary">
                            {{ s.category_name }}
                          </span>
                        </router-link>
                      </div>
                    </div>
                  </div>
                  <div class="card-body">
                    <div class="item7-card-desc d-flex mb-2">
                      <router-link :to="'/blog/' + item.short_name"
                        ><i class="fa fa-calendar-o text-muted me-2"></i
                        >{{ item.created_at }}</router-link
                      >
                      <div
                        class="ms-auto"
                        style="width: fit-content; float: right"
                      >
                        <router-link :to="'/blog/' + item.short_name"
                          ><i class="fa fa-comment-o text-muted me-2"></i
                          >{{ item.commentcount }}</router-link
                        >
                      </div>
                    </div>
                    <router-link
                      :to="'/blog/' + item.short_name"
                      class="text-dark"
                    >
                      <h4 class="font-weight-semibold" :title="item.title">{{ item.title.length>35?item.title.substring(0, 32)+'...':item.title }}</h4>
                    </router-link>
                    <p :title="item.description">{{ item.description.length>50?item.description.substring(0, 47)+'...':item.title }}</p>
                    <router-link
                      :to="'/blog/' + item.short_name"
                      class="ms-auto btn btn-primary btn-sm w-100"
                      >Daha fazla</router-link
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="center-block text-center">
              <Pagination
                :currentpage="currentpage"
                :pagesize="pagesize"
                :preload="'/blog/page/'"
              />
            </div>
          </div>
          <!--/BLOG LIST-->

          <!--Right Side Content-->
          <div class="col-xl-4 col-lg-4 col-md-12">
            <Item1 :item="ads" />
            <div class="card">
              <div class="card-body">
                <div class="input-group">
                  <input
                    type="text"
                    class="form-control br-ts-7 br-bs-7"
                    placeholder="Blog İçinde"
                    @keyup="filterPage"
                    id="search"
                  />
                  <div class="input-group-text border-0 bg-transparent p-0">
                    <button
                      type="button"
                      class="btn btn-primary br-te-7 br-be-7"
                      @click="search"
                    >
                      Ara
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <SideBar :categoryList="categoryList" />
          </div>
        </div>
      </div>
    </section>
    <!--Add listing-->
  </div>
</template>
<style scoped>
@media screen and (max-width: 990px) {
  #mobileEx {
    padding-left: 35px;
    padding-right: 35px;
  }
}
</style>
<script>
import SideBar from "./Components/SideBar.vue";
import Breadcrumb from "../../components/Breadcrumb.vue";
import Pagination from "../../components/Pagination.vue";
import Item1 from "../../components/Ads/Item1.vue";
export default {
  metaInfo() {
    return {
      title: "Makaleler - Terapi Vitrini",
      meta: [
        { charset: "utf-8" },
        {
          name: "description",
          content:
            "Makaleler - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
        },
      ],
    };
  },
  created() {
    this.$store.state.header = true;
    this.$store.state.footer = true;

    
    if (this.$route.params.page) {
      this.currentpage = this.$route.params.page;
    }
    let blogInfo = {
      begin: (this.currentpage - 1) * this.perpage,
      perpage: this.perpage,
    };
    this.$store.dispatch("blogList", blogInfo).then((value) => {
      this.ads = value.ads;
      this.blogList = value.list;
      this.totalsize = value.size;
      this.pagesize = value.pages;
      this.blogLoad = false;
      let _this = this;
      $(document).ready(function () {
        let content = document.getElementById("appcontent").innerHTML;
        let title = this.currentpage=1?"Makaleler - Terapi Vitrini":"Makaleler - Terapi Vitrini Sayfa:"+this.currentpage;
        _this.$store.dispatch("setMetaContent", [
          title,
          "Makaleler - Terapi Vitrini ile 7/24 lisanslı, profesyonel bir terapiste erişim sağlayabilirsiniz",
          "",
          "",
          "",
          content,
        ]);
      });
    });
    this.$store.dispatch("categoryList").then((value) => {
      this.categoryList = value;
    });
  },
  data() {
    return {
      ads: [],
      categoryList: [],
      blogList: [],
      currentpage: 1,
      perpage: 8,
      totalsize: 0,
      pagesize: 0,
      blogLoad: true,
    };
  },
  watch: {
    "$route.params.page": function () {
      this.blogLoad = true;
      this.currentpage = this.$route.params.page;
      let blogInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
      };
      this.$store.dispatch("blogList", blogInfo).then((value) => {
        this.blogList = value.list;
        this.totalsize = value.size;
        this.pagesize = value.pages;
        this.blogLoad = false;
      });
    },
  },
  methods: {
    backToTop() {
      $("html, body").animate(
        {
          scrollTop: 0,
        },
        0
      );
      return false;
    },
    filterPage() {
      if (event.key === "Enter") {
        this.search();
      }
    },
    search() {
      let search = document.getElementById("search").value;
      this.currentpage = 1;
      this.blogList = [];
      this.blogLoad = true;
      let blogInfo = {
        begin: (this.currentpage - 1) * this.perpage,
        perpage: this.perpage,
        search: search,
      };
      this.$store.dispatch("blogList", blogInfo).then((value) => {
        this.blogList = value.list;
        this.totalsize = value.size;
        this.pagesize = value.pages;
        this.blogLoad = false;
      });
    },
  },
  components: { SideBar, Breadcrumb, Pagination, Item1 },
  mounted() {
    this.backToTop();
  },
};
</script>